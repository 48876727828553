import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TaskList from './components/TaskList';
import TaskForm from './components/TaskForm';

function App() {
  const [tasks, setTasks] = useState([]);
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAccessToken(token);
      fetchTasks(token);
    }
  }, []);

  const fetchTasks = async (token) => {
    try {
      const response = await axios.get('http://localhost:3000/api/tasks', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleLogin = (token) => {
    localStorage.setItem('accessToken', token);
    setAccessToken(token);
    fetchTasks(token);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setAccessToken('');
    setTasks([]);
  };

  const addTask = async (task) => {
    try {
      const response = await axios.post('http://localhost:3000/api/tasks', task, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setTasks([...tasks, response.data]);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const updateTask = async (updatedTask) => {
    try {
      const response = await axios.put(`http://localhost:3000/api/tasks/${updatedTask.id}`, updatedTask, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setTasks(tasks.map(task => (task.id === updatedTask.id ? response.data : task)));
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const deleteTask = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/api/tasks/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setTasks(tasks.filter(task => task.id !== id));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  return (
    <div>
      <h1>Task Manager</h1>
      {accessToken ? (
        <div>
          <button onClick={handleLogout}>Logout</button>
          <TaskForm addTask={addTask} />
          <TaskList tasks={tasks} updateTask={updateTask} deleteTask={deleteTask} />
        </div>
      ) : (
        <div>
          <h2>Login</h2>
          <form onSubmit={async (e) => {
            e.preventDefault();
            const username = e.target.username.value;
            const password = e.target.password.value;
            try {
              const response = await axios.post('http://localhost:3000/api/auth/login', { username, password });
              handleLogin(response.data.accessToken);
            } catch (error) {
              alert('Invalid username or password');
            }
          }}>
            <input type="text" name="username" placeholder="Username" required />
            <input type="password" name="password" placeholder="Password" required />
            <button type="submit">Login</button>
          </form>
          <h2>Register</h2>
          <form onSubmit={async (e) => {
            e.preventDefault();
            const username = e.target.username.value;
            const password = e.target.password.value;
            try {
              await axios.post('http://localhost:3000/api/auth/register', { username, password });
              alert('User registered');
            } catch (error) {
              alert('Registration failed');
            }
          }}>
            <input type="text" name="username" placeholder="Username" required />
            <input type="password" name="password" placeholder="Password" required />
            <button type="submit">Register</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;