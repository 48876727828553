import React from 'react';

function TaskList({ tasks, updateTask, deleteTask }) {
  return (
    <div>
      <h2>Tasks</h2>
      {tasks.map(task => (
        <div key={task.id} style={{ marginBottom: '10px' }}>
          <h3>{task.title}</h3>
          <p>{task.description}</p>
          <p>Status: {task.status}</p>
          <p>Assigned to: {task.assigned_to}</p>
          <button onClick={() => {
            const newStatus = task.status === 'pending' ? 'completed' : 'pending';
            updateTask({ ...task, status: newStatus });
          }}>
            Toggle Status
          </button>
          <button onClick={() => deleteTask(task.id)}>Delete</button>
        </div>
      ))}
    </div>
  );
}

export default TaskList;